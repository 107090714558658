import store from '@/store';

const getMenu = function () {
    const user = store.getters.currentUser;
    const menu = [
        {
            icon : 'fa fa-tachometer',
            text : 'Dashboard',
            link : '/app/'
        },
        {
            icon : 'fa fa-picture-o',
            text : 'Media',
            link : '/media/'
        },
        {
            type : 'separator'
        },
        {
            icon              : 'fa fa-user-o',
            text              : user.name,
            link              : '#',
            dropdownPlacement : 'right',
            items             : [
                {
                    text : 'Change Password',
                    link : '/app/change-password/'
                },
                {
                    text : 'Logout',
                    link : '/app/logout/'
                }
            ]
        }
    ];
    return [
        ...menu
    ];
};
export default getMenu;
