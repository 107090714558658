<template>
    <div class="page page-normal">
        <notifications/>
        <lego-nav-bar logo-text="Admin Panel" has-separator colored class="bgc-primary" drop-down-animation="bounce" :items="menu">
<!--            <template slot="logo">-->
<!--                <div class="fl-eq" @click="$router.push('/app/')">-->
<!--                    <img style="height: 35px;" alt="contractAce" src="../assets/image/logo.jpg">-->
<!--                </div>-->
<!--            </template>-->
        </lego-nav-bar>
        <div class="page-content container-fluid">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
        <div class="bgc-primary-900 p-3">
            Powered by Xeoscript Technologies
        </div>
    </div>
</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';

export default {
    components : {
        LegoNavBar
    },
    data () {
        return {
            menu : getMenu()
        };
    },
    metaInfo : {
        titleTemplate : '%s - Anathababu',
        htmlAttrs     : {
            lang : 'en',
            amp  : true
        }
    },
    name : 'MainLayout'
};
</script>
